export const Bio = {
  name: "Muhamad Diaz",
  roles: [
    "Full Stack Developer",
    "UI/UX Designer",
    "Programmer",
  ],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  github: "https://github.com/diazakbar",
  resume:
    "https://wingkoumbul.my.canva.site/professional-minimalist-cv-resume",
  linkedin: "https://www.linkedin.com/in/muhamad-diaz-bb651a1bb/",
  twitter: "https://twitter.com/bitteroyi/",
  insta: "https://www.instagram.com/mdariscool/",
  facebook: "https://www.facebook.com/muhamad.diaz.77715",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "Next Js",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
    ],
  },
  {
    title: "DevOps",
    skills: [
      {
        name: "Google Cloud",
        image:
          "https://static-00.iconduck.com/assets.00/google-cloud-platform-logo-icon-2048x1824-pg4wzspq.png",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "Nginx",
        image: "https://download.logo.wine/logo/Nginx/Nginx-Logo.wine.png",
      },
    ],
  },
  {
    title: "Android",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Android Studio",
        image:
          "https://developer.android.com/static/studio/images/new-studio-logo-1_1920.png",
      },
    ],
  },
  {
    title: "Machine Learning",
    skills: [
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://e7.pngegg.com/pngimages/713/558/png-clipart-computer-icons-pro-git-github-logo-text-logo-thumbnail.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
      },
    ],
  },
];

export const experiences = [
  // {
  //   id: 0,
  //   img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/neurobit.jpeg?alt=media&token=1cacbb7b-e5ea-4efb-9b3d-f5a31b9564ad",
  //   role: "Frontend Engineer Intern",
  //   company: "Neurobit",
  //   date: "June 2023 - Nov 2023",
  //   desc: "Enhanced user experiences on Neurobit PSG & Hybrid, Portals by resolving bugs & reduced load time by 40%. Built Neurobit Analytics portal using React Js with seamless interaction of REST APIs using AXIOS optimized with React Query. Refactored previous code to TypeScript, updated with new dependency and integrated Vite with Jest for Unit Testing.",
  //   skills: [
  //     "ReactJS",
  //     "Redux",
  //     "NodeJs",
  //     "Material UI",
  //     "HTML",
  //     "CSS",
  //     "JavaScript",
  //   ],
  //   doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Screenshot%20from%202024-01-25%2022-38-31.png?alt=media&token=2785903f-1a4e-41f5-afd2-6adcfe56d058",
  // },
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "DevOps & Fullstack Engineering Intern",
    company: "Flipr Inovations Pvt. Ltd.",
    date: "Aug 2023 - Oct 2023",
    desc: "Built Flipr Connect Platforms using React Js integrated GraphQL with AXIOS, created High level Design and Figma design. Built Backend with GraphQL and Node JS and connected with MongoDb - Reducing API calls by 20%. Dockerized and automated with Kubernetes & Jenkins CI/CD deployed in AWS-EC2 added Prometheus & Grafana for monitoring.",
    skills: [
      "Nginx",
      "JavaScript",
      "Node Js",
      "Next Js",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1696514649120.jpeg?alt=media&token=e7f6757b-edfa-4138-a692-d6709eeef3e2",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Rudraksha.jpeg?alt=media&token=8f83f41e-d0a1-486f-9c7f-dd4cd1d09e8d",
    role: "Android Developer Intern",
    company: "Rudraksha Welffare Foundation",
    date: "June 2021 - Oct 2021",
    desc: "• Built RudraShakti - Astrologer App, with MVVM Architecture using Java and Android Studio integrating Firebase SDK. Created One to One video call integration with Socket.IO and Firebase cloud functions and integrated with Retrofit. Created Low Level Design and converted Figma design to XML code.",
    skills: [
      "Android",
      "Java",
      "Node Js",
      "Figma",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/WhatsApp%20Image%202023-05-05%20at%2012.07.39%20AM.jpeg?alt=media&token=9f0e1648-568b-422d-bd0b-1f125f722245",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/flipr.jpeg?alt=media&token=1d72532a-45eb-4c1a-a81a-c9bed9fec543",
    role: "Fullstack Externship",
    company: "Flipr",
    date: "June 2023 - July 2023",
    desc: "Built an employee management full stack web app used Docker and deployed on AWS ec2. I was the top performer in the program.",
    skills: [
      "ReactJS",
      "NodeJs",
      "HTML",
      "CSS",
      "JavaScript",
    ],
    doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1691181448873.jpeg?alt=media&token=ee85eb8f-7247-43cd-9a1d-ce9f58ea62a6",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/gdsc.jpeg?alt=media&token=c162329c-efaa-4be8-a173-8d3f4c48ea70",
    role: "Android Developer",
    company: "DSC KIIT",
    date: "Nov2021 - Present",
    desc: "As an Android developer at the Google Developers Student Club (GDCS), I have had the opportunity to work on exciting projects and collaborate with talented developers who share my passion for technology. Through my involvement with GDCS, I have also had the opportunity to host and participate in numerous events, including hackathons, study jams, and workshops.",
    skills: [
      "Leadership",
      "Mobile Application Development",
      "Kotlin",
      "XML",
      "Figma",
    ],
  },
  {
    id: 4,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/girlScript.jpeg?alt=media&token=e656a621-cf3c-4230-bf0f-e74b4cec6035",
    role: "Open Source Contributor ",
    company: "GirlScript Summer of Code",
    date: "May 2023 - Present",
    desc: "Contributed to different open-source projects and learn from industry experts",
  },
];

export const education = [
  {
    id: 0,
    img: "https://fia.ui.ac.id/wp-content/uploads/182/2018/01/logo-UI.png",
    school: "University of indonesia",
    date: "2024 - now",
    degree: "FMIPA-Physic",
  },
  {
    id: 1,
    img: "https://www.budhiwarman.id/assets/images/logo-sm-dark.png",
    school: "Budhi Warman Senior High SchoolS",
    date: "2021-2024",
    degree: "Mathematics and Natural Science",
  },
  {
    id: 2,
    img: "https://yt3.googleusercontent.com/XUGAtXRFvVP7yK_YLqqeMv1SIA2BSRo8g9_j4rhO20zjvOldzPqbYA5ZAyFKdqSUf66ULfI4ww=s900-c-k-c0x00ffffff-no-rj",
    school: "SMPN 20 Jakarta Junior High School",
    degree: "2018 - 2021",
  },
  {
    id: 3,
    img: "https://muhamaddiaz.com/img/logosd.png",
    school: "SDN Batu Ampar 01 Elementary School",
    degree: "2012 - 2018",
  },
];

export const projects = [
  {
    id: 0,
    title: "QR Code Generator",
    date: "Dec 2024",
    description:
      "Menghasilkan qr code dari link yang ingin kalian ubah",
    image:
      "https://raw.githubusercontent.com/diazakbar/Qr-Generator-Using-HTML/refs/heads/main/Capture.JPG",
    tags: [
      "HTML",
      "JavaScript",
      "CSS",
    ],
    category: "HTML",
    github: "https://github.com/diazakbar/Qr-Generator-Using-HTML",
    webapp: "https://decisionhub.netlify.app/",
  },
  {
    id: 1,
    title: "Tic Tac Toe Game",
    date: "Dec 2024",
    description:
      "Game yang dibuat menggunakan menggunakan html dan css",
    image:
      "https://raw.githubusercontent.com/diazakbar/Tic-Tac-Toe/refs/heads/main/Capture.JPG",
    tags: [
      "HTML",
      "CSS",
    ],
    category: "HTML",
    github: "https://github.com/diazakbar/Tic-Tac-Toe",
    webapp: "https://trackify-management.netlify.app/",
  },
  {
    id: 2,
    title: "Kalkulator Using HTML",
    date: "Dec 2024",
    description:
      "Kalkulator yang dibuat dengan html, css, dan javascript dan diinterasikan kedalam web",
    image:
      "https://raw.githubusercontent.com/diazakbar/Calculator/refs/heads/main/Capture.JPG",
    tags: ["React Js", "MongoDb", "Node Js", "Express Js", "Redux"],
    category: "web app",
    github: "https://github.com/rishavchanda/Podstream",
    webapp: "https://podstream.netlify.app/",
  },
  {
    id: 3,
    title: "Comming Soon Page",
    date: "Dec 2024",
    description:
      "Website seperti penghitung waktu yang biasanya digunakan untuk menampilkan peresmian/acara yang akan mendatang ",
    image:
      "https://raw.githubusercontent.com/diazakbar/Comming-soon-page/refs/heads/main/Capture.JPG",
    tags: [
      "HTML",
      "CSS",
      "Javascript",
    ],
    category: "HTML",
    github: "https://github.com/diazakbar/Comming-soon-page/",
    webapp: "https://vexa-app.netlify.app/",
  },
  {
    id: 0,
    title: "Task Manager",
    date: "Dec 2024",
    description:
      "Website local yang bisa digunakan untuk management tugas perusahaan anda ",
    image:
      "https://raw.githubusercontent.com/diazakbar/Task-manager/refs/heads/main/Capture.JPG",
    tags: [
      "HTML",
      "PHP",
    ],
    category: "PHP",
    github: "https://github.com/diazakbar/Task-manager",
    webapp: "https://vexa-app.netlify.app/",
  },
  {
    id: 0,
    title: "Password Manager",
    date: "Dec 2024",
    description:
      "Website ini dapat digunakan untuk memanagement berbagai password anda ",
    image:
      "https://raw.githubusercontent.com/diazakbar/Password-Manager/refs/heads/main/Capture.JPG",
    tags: [
      "HTML",
      "PHP",
    ],
    category: "PHP",
    github: "https://github.com/diazakbar/Password-Manager",
    webapp: "https://vexa-app.netlify.app/",
  },
];

